<template>
    <div class="container mt-4">
        <div class="rounded alert alert-dismissible alert-light" role="alert" v-if="errorMessage">
            <div class="d-flex align-items-center">
                <i class="fas fa-exclamation-triangle text-danger mr-2"></i>
                Error: {{ errorMessage }}
            </div>
            <button type="button" class="ml-auto close" @click="errorMessage=''">&times;</button>
        </div>
        <div class="card mb-3">
            <div class="card-header d-flex align-items-center">
                <div>Report Transactions</div>
            </div>
            <Spinner v-if="loading" size="60" line-fg-color="gray" style="padding-top: 100px; padding-bottom: 100px;" />
            <form v-else-if="!loadingError" @submit.prevent="createTransaction()" class="card-body">
                <fieldset :disabled="addingTransaction" class="d-flex flex-column">
                    <div class="row no-gutters">
                        <div class="form-group col-md-2 mb-3 pr-md-3">
                            <label for="processor">Processors</label>
                            <select v-model="selectedProcessorId" id="processor" class="form-control" required>
                                <option v-for="processor in processors"
                                        :key="processor.id"
                                        :value="processor.id">
                                    {{ processor.name }}
                                </option>
                            </select>
                        </div>
                        <div class="form-group col-md-5 mb-3 pr-md-3">
                            <label for="customer-bank-account">Customer Bank Accounts</label>
                            <select v-model="selectedCustomerBankAccountId"
                                    id="customer-bank-account"
                                    class="form-control"
                                    required>
                                <option :value="null" disabled hidden>
                                    Select an account
                                </option>
                                <optgroup v-for="(accounts, customer) in customers"
                                          :label="accounts[0].name"
                                          :key="customer">
                                    <option v-for="account in accounts"
                                            :key="account.id"
                                            :value="account.id">
                                        {{ account.id }}
                                    </option>
                                </optgroup>
                            </select>
                        </div>
                        <div class="form-group col-md-5 mb-3">
                            <label for="transaction-id">Transaction ID</label>
                            <input v-model="transactionId"
                                   type="text"
                                   id="transaction-id"
                                   class="form-control"
                                   placeholder="Enter a Processor Transaction ID"
                                   required>
                        </div>
                    </div>
                    <button type="submit" class="btn btn-sm btn-primary ml-auto">Submit</button>
                </fieldset>
            </form>
        </div>
        <transition-group name="fade" mode="out-in">
            <div v-for="(transaction, index) in transactions" :key="index" class="card mb-3">
                <div class="card-header">
                    <span>Transaction: {{ transaction.id }}</span>
                    <button @click="transactions.splice(index, 1)" class="ml-auto close" type="button">
                        &times;
                    </button>
                </div>
                <div class="card-body code-card-body shadow">
                    <pre class="pre-scrollable"><code>{{ transaction }}</code></pre>
                </div>
            </div>
        </transition-group>
    </div>
</template>

<script>
    import _ from 'lodash';
    import Spinner from "vue-simple-spinner";
    import {getProcessors, getCustomerBankAccounts, createTransaction} from "../../services/SOAPService";

    export default {
        name: "CreateTransaction",
        components: {
            Spinner
        },
        data() {
            return {
                loading: true,
                loadingError: false,
                addingTransaction: false,
                errorMessage: null,
                processors: [],
                customerBankAccounts: [],
                selectedProcessorId: null,
                selectedCustomerBankAccountId: null,
                transactionId: null,
                transactions: []
            }
        },
        computed: {
            customers() {
                return _.groupBy(this.customerBankAccounts, account => account.customer);
            }
        },
        async mounted() {
            try {
                const [{ data: processors }, { data: customerBankAccounts }] = await Promise.all([
                    getProcessors(),
                    getCustomerBankAccounts()
                ]);

                this.processors = processors;
                this.selectedProcessorId = this.processors[0].id
                this.customerBankAccounts = customerBankAccounts;
                this.loadingError = false;
            } catch (error) {
                this.errorMessage = 'Failed to load page.';
                this.loadingError = true;
            } finally {
                this.loading = false;
            }
        },
        methods: {
            async createTransaction() {
                try {
                    this.addingTransaction = true;
                    const { data: transaction } = await createTransaction(
                        this.transactionId,
                        this.selectedProcessorId,
                        this.selectedCustomerBankAccountId
                    );
                    this.transactions.push(transaction)

                    // Reset transaction id
                    this.transactionId = null;
                } catch (error) {
                    this.errorMessage = error?.response?.data?.detail || 'An error has occurred.';
                } finally {
                    this.addingTransaction = false;
                }
            }
        }
    }
</script>

<style scoped>
    .code-card-body {
        background-color: #1A1A1A;
        border-bottom-left-radius: 0.625rem;
        border-bottom-right-radius: 0.625rem;
    }

    .code-card-body > pre {
        color: #FFFFFF;
    }

    .fade-enter-active,
    .fade-leave-active {
        transition-duration: 0.25s;
        transition-property: opacity;
        transition-timing-function: ease;
    }

    .fade-enter,
    .fade-leave-active {
        opacity: 0;
    }

    .close {
        color: #495057!important;
    }
</style>
